import React, { Fragment } from "react"

import { Formik, Form } from "formik"
import { string, object } from "yup"
import { navigate } from "gatsby"

import { FormItem } from "../components/form"

const schema = object()
  .shape({
    name: string().required().default(""),
    url: string().default("").defined(),
    email: string().email().required().default(""),
    subject: string().required().default(""),
    message: string().required().default(""),
  })
  .defined()

interface SchemaType {
  [key: string]: string
  name: string
  url: string
  email: string
  subject: string
  message: string
}

const encode = (data: SchemaType) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default function Template() {
  return (
    <Fragment>
      <div id="pageheader">
        <h1>Contact</h1>
      </div>
      <Formik
        validationSchema={schema}
        enableReinitialize={true}
        initialValues={schema.default()}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true)
          fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "contact", ...values }),
          })
            .then(() => {
              setSubmitting(false)
              navigate("/contact/thank-you/")
            })
            .catch((error) => {
              setSubmitting(false)
              throw error
            })
        }}
      >
        {({ values, handleChange, handleBlur, isSubmitting }) => (
          <Form
            name="contact"
            action="/contact/thank-you/"
            method="POST"
            netlify-honeypot="url"
            data-netlify="true"
            noValidate={true}
            className="w-full max-w-sm"
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="contact" />
            <FormItem name="name" label="Name" />
            <div className="hidden">
              Don’t fill this out if you&apos;re human:
              <input
                type="text"
                name="url"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.url}
              />
            </div>
            <FormItem name="email" label="E-mail" />
            <FormItem name="subject" label="Subject" />
            <FormItem
              name="message"
              label="Message"
              component="textarea"
              rows={10}
            />
            <button
              type="submit"
              className="bg-bm-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              disabled={isSubmitting}
            >
              Submit
            </button>
          </Form>
        )}
      </Formik>
    </Fragment>
  )
}
